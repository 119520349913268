<template>

  <div id="app" class="wrapper">
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>

<script>

import LoadingComp from "./components/LoadingComp";
import LoadingInplayComp from "./components/LoadingInplayBetComp";
import LoginComp from "./components/LoginComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_HIDE_LOADING_INPLAY} from "@/store/mutation-types";


export default {
  components: { LoginComp, LoadingInplayComp, LoadingComp
  },
  data() {
    return {}
  },
  methods:{

  },
  created() {
    if (sessionStorage.getItem('state')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('state'))))
    }
    window.addEventListener('pagehide', (ev) => {
      this.$store.commit(RECEIVE_HIDE_LOADING_INPLAY)
      this.$store.commit(RECEIVE_HIDE_LOADING)
      this.$store.state.sportsCartItems = []
      //window.sessionStorage.clear();
      //sessionStorage.setItem('state', '')
      sessionStorage.setItem('state', JSON.stringify(this.$store.state))
      return false
    })
  }
}
</script>
