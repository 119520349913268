export default {
  YES:1,
  NOT:0,
  ENABLE: 1,
  DISABLE: 0,
  UNCHECKED: 2,
  CHECKED: 1,
  STATUS_REPLYED :2,
  LOW_BEDANG_FLAG: 1.4,

  INPLAY_YES:1,
  INPLAY_NOT:0,

  GAME_PROTO: 1,
  GAME_LEISURE: 2,

  CROSS_RULE_TYPE_FULLTIME: 1,
  CROSS_RULE_TYPE_HANLFTIME: 2,

  IS_POPULAR_LEAGUE_YES: 1,
  IS_POPULAR_LEAGUE_NOT: 0,

  CUSTOMER_TYPE_NORMAL: 1,
  CUSTOMER_TYPE_BANK_CARD: 2,

  BONUS_BEDANG_EXCEPT_FLAG: 1.35,

  /*DISPLAY POSITION*/
  GAME_DISPLAYPOSITION_1x2: 1,
  GAME_DISPLAYPOSITION_HANDICAPOVERUNDER: 2,
  GAME_DISPLAYPOSITION_SPECIAL: 3,
  GAME_DISPLAYPOSITION_LIVE: 4,
  GAME_DISPLAYPOSITION_PBA: 10,
  //경기종류 승무패,핸디캡,오버언더
  GAME_KIND_1X2: 1,
  GAME_KIND_HANDICAP: 2,
  GAME_KIND_OVERUNDER: 3,
  GAME_KIND_ODD_EVEN: 10,
  GAME_KIND_BOTH_TEAM_GOAL_IN: 11,
  GAME_KIND_FINAL_SCORE: 12,
  GAME_KIND_HITS_COUNT: 13,
  GAME_KIND_OTHER: 99,

  /*대기중*/
  GAME_RESULT_WATING: 0,
  /*홈팀승*/
  GAME_RESULT_WIN_HOME: 1,
  /*무*/
  GAME_RESULT_DRAW: 2,
  /*원정승*/
  GAME_RESULT_WIN_AWAY: 3,
  /*적중특례*/
  GAME_RESULT_SPECIAL: 4,
  /*취소*/
  GAME_RESULT_CANCELED: 5,

  WAY_HOME: 1,
  WAY_DRAW: 2,
  WAY_AWAY: 3,
  WAY_NONE: -1,

  GAME_TYPE_NOT_SELECTED: -1,
  GAME_TYPE_ALL: 0,
  GAME_TYPE_SOCCER: 49,
  GAME_TYPE_BASEBALL: 50,
  GAME_TYPE_BASKETBALL: 51,
  GAME_TYPE_VOLLEYBALL: 52,
  GAME_TYPE_HOCKEY: 53,
  GAME_TYPE_USA_FOOTBALL: 54,
  GAME_TYPE_TENNIS: 55,
  GAME_TYPE_ESPORTS: 56,
  GAME_TYPE_PBA: 59,

  //베팅결과
  BET_RESULT_WAITING: 0,
  BET_RESULT_WIN: 1,
  BET_RESULT_LOSE: 2,
  BET_RESULT_SPECIALCASE: 3,
  BET_RESULT_CANCEL: 4,

  /*경기체크등록중(배팅불가,유저페이지에 보여안짐)*/
  GAME_STATUS_CHECKING: 0,
  /*경기오픈(베팅가능)*/
  GAME_STATUS_OPEN: 1,
  /*경기시작(베팅마감)*/
  GAME_STATUS_STARTING: 2,
  /*경기종료(베팅마감),결과처리*/
  GAME_STATUS_END: 3,

  /*게시판*/
  BOARD_TYPE_FREE_BOARD: 1, //게시글
  BOARD_TYPE_NOTICE: 2, //공지사항
  BOARD_NOTICE_TYPE_IN_FREEBOARD: 1,//기본공지
  BOARD_NOTICE_TYPE_LINE: 2,//한줄공지
  BOARD_NOTICE_TYPE_IN_BETFORM: 3,//베팅창공지

  /*입출금*/
  APPLY_STATUS_WAITING: 0,
  APPLY_STATUS_CHECKING: 1,
  APPLY_STATUS_COMPLITE: 2,
  APPLY_STATUS_CANCEL: 3,
  APPLY_TYPE_RECHARGE: 1,
  APPLY_TYPE_EXCHANGE: 2,

  APPLY_BONUS_YES1: 1,
  APPLY_BONUS_YES2: 2,
  APPLY_BONUS_YES3: 3,
  APPLY_BONUS_YES4: 4,
  APPLY_BONUS_NOT: 0,
  APPLY_BONUS_NOSET: -1,


  RECHARGE_FIRSTBONUS : 0,
  RECHARGE_DAYBONUS : 1,
  RECHARGE_NOBONUS : 2,

  APPLY_COINSITE_URL : 'https://desco.lightbk.com/',

  /*쪽지*/
  MESSAGE_TYPE_PRIVATE: 1,
  MESSAGE_TYPE_PUBLIC: 2,

  /*팝업위치*/
  POPUP_PLATFORM_MAIN: 1,
  POPUP_PLATFORM_LOGIN: 2,


}
